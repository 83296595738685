import { Col, Row, Card } from "antd"
import React from "react"

const MyContact = () => {
	return (
		<>
			<Row>
				<Col style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
					<Card
						bordered={false}
						style={{
							width: 400,
							boxShadow: "0 2px 35px -5px rgba(0, 0, 0, 0.19)",
							transition: "all 0.5s",
							borderRadius: "10px ",
						}}
					>
						<h3
							style={{
								fontFamily: "Proxima Soft",
								fontWeight: 700,
								fontSize: "2.5rem",
								lineHeight: "1.5",
								textAlign: "start",
								color: "#02285b",
							}}
						>
							Imran Afzal
						</h3>
						<p
							style={{
								fontFamily: "Proxima Soft",
								fontWeight: 600,
								fontSize: "2.2rem",
								lineHeight: "1.5",
								textAlign: "start",
								color: "#455963",
							}}
						>
							imran_falacy@yahoo.com
						</p>
						<p
							style={{
								fontFamily: "Proxima Soft",
								fontWeight: 600,
								fontSize: "2rem",
								textAlign: "start",
								color: "#455963",
							}}
						>
							+923027617842
						</p>
					</Card>
				</Col>
			</Row>
		</>
	)
}
export default MyContact
